const rootImgPath = '/images'

const rootIconPath = '/images/icons'

const makePath = (root: string, imgName: string) => `${root}/${imgName}`

export default {
  logo: makePath(rootImgPath, 'logo.png'),
  mdLogo: makePath(rootImgPath, 'md-logo.png'),
  lgLogo: makePath(rootImgPath, 'lg-logo.png'),
  banner: makePath(rootImgPath, 'banner.png'),
  flagUk: makePath(rootIconPath, 'flag-uk.svg'),
  flagEs: makePath(rootIconPath, 'flag-es.svg'),
  flagIt: makePath(rootIconPath, 'flag-it.svg'),
  flagFr: makePath(rootIconPath, 'flag-fr.svg'),
  flagDe: makePath(rootIconPath, 'flag-de.svg'),
  flagRu: makePath(rootIconPath, 'flag-ru.svg'),
  flagPt: makePath(rootIconPath, 'flag-pt.svg'),
  flagUkBw: makePath(rootIconPath, 'flag-uk-bw.svg'),
  flagEsBw: makePath(rootIconPath, 'flag-es-bw.svg'),
  flagItBw: makePath(rootIconPath, 'flag-it-bw.svg'),
  flagFrBw: makePath(rootIconPath, 'flag-fr-bw.svg'),
  flagDeBw: makePath(rootIconPath, 'flag-de-bw.svg'),
  flagRuBw: makePath(rootIconPath, 'flag-ru-bw.svg'),
  flagPtBw: makePath(rootIconPath, 'flag-pt-bw.svg'),
  arrow: makePath(rootIconPath, 'arrow.svg'),
  facebook: makePath(rootIconPath, 'facebook.svg'),
  message: makePath(rootIconPath, 'message.svg'),
  instagram: makePath(rootIconPath, 'instagram.svg'),
  bannerTop: makePath(rootImgPath, 'banner-top.jpg'),
  bannerBottom: makePath(rootImgPath, 'home-banner-bottom.png'),
  clock: makePath(rootIconPath, 'clock.svg'),
  cross: makePath(rootIconPath, 'cross.svg'),
  userPlaceholder: makePath(rootImgPath, 'user-placeholder.png'),
  bracket: makePath(rootIconPath, 'bracket.svg'),
  calendar: makePath(rootIconPath, 'calendar.png'),
}
