// breakpoints
type breakpoints = {
  [key: string]: number
}

const SM = 768
const MD = 1280
const LG = 1440

const makeMediaMinWidth = (size: number) => `@media (min-width: ${size}px)`
const makeMediaMaxWidth = (size: number) => `@media (max-width: ${size}px)`

const makeIsLessThen = (compare: number) => (width: number) => width <= compare

export const bp: breakpoints = {
  sm: SM,
  md: MD,
  lg: LG,
}

export default {
  min: {
    sm: makeMediaMinWidth(SM),
    md: makeMediaMinWidth(MD),
    lg: makeMediaMinWidth(LG),
  },
  max: {
    sm: makeMediaMaxWidth(SM - 0.02),
    md: makeMediaMaxWidth(MD - 0.02),
    lg: makeMediaMaxWidth(LG - 0.02),
  },
  isSM: makeIsLessThen(SM),
  isMD: makeIsLessThen(MD),
  isLG: makeIsLessThen(LG),
}
