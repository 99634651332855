export default {
  family: {
    primary: 'Open Sans, sans-serif',
  },
  weight: {
    primary: {
      light: 300,
      regular: 400,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
    },
  },
}
