import media, { bp } from './media'

export default {
  '.container': {
    paddingLeft: 15,
    paddingRight: 15,
    margin: [0, 'auto'],
    width: '100%', // dont delete

    // TODO: test how will behave
    [media.min.sm]: {
      padding: [0, 60],
      maxWidth: bp.sm,
    },

    [media.min.md]: {
      padding: [0, 60],
      maxWidth: bp.md,
    },

    [media.min.lg]: {
      padding: [0, 60],
      maxWidth: bp.lg,
    },
  },

  '&.container-wide': {
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  '&.no-padding': {
    paddingLeft: 0,
    paddingRight: 0,
  },
}
