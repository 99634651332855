export default {
  white: '#ffffff',
  black: '#000000',
  yellow: '#FDCD25',
  grey: '#E5E5E5',
  lightGrey: '#B2BCC4',
  darkGrey: '#505050',
  inactiveGrey: '#DCDCDC',
  contrastGrey: '#A0A0A0',
  silver: '#F3F3F3',
  blue: '#1F8BDA',
  lightBlue: '#D9E5EE',
  milk: '#F1F1F1',
  accent: '#DA1F1F',
  chatMsg: '#f2f2f2',
}
